import dynamic from 'next/dynamic'
import App from 'next/app'
import { StyledEngineProvider, AscendThemeProvider } from '@achieve/ascend'
import CssBaseline from '@mui/material/CssBaseline'
const Layout = dynamic(() => import('components/Layout'))
import { CacheProvider } from '@emotion/react'
import { createEmotionCache } from 'utils/emotion'
import { AnalyticsProvider } from 'providers/AnalyticsProvider'
import { FeatureFlagProvider } from 'providers/FeatureFlagProvider'
import { DTIProvider } from 'providers/DTIProvider'
import { PhoneDataProvider } from 'providers/PhoneDataProvider'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import getConfig from 'next/config'

import { useParams } from 'next/navigation'
import { useEffect } from 'react'

import 'styles/global.css'

import { useRouter } from 'next/router'

// Specific fonts for Achieve.com public site
import './fonts.scss'

// Global Typography utility classes (not using Ascend/Ascend package)
import './typography.scss'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

import '@contentful/live-preview/style.css'
import {
  DEFAULT_EXPERIMENT,
  OFF_EXPERIMENT,
  UNASSIGNED_EXPERIMENT,
  getPageVariationByParam,
} from 'constants/experiments'

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  publicProcessDotEnv,
  pageProps = {},
  host,
  hostProtocol,
}) {
  const params = useParams()

  useEffect(() => {
    if (window) {
      let hash = window.location.hash
      if (!hash) {
        return
      } else {
        let anchorIndex = hash.indexOf('#')
        let queryIndex = hash.indexOf('?')
        if (queryIndex > anchorIndex && queryIndex > -1 && anchorIndex > -1) {
          let dividedUrl = hash.split('#')
          let anchorAndQuery = dividedUrl[1].split('?')
          if (router.asPath) {
            router.push(
              `${router.asPath.split('#')[0]}${dividedUrl[0]}?${anchorAndQuery[1]}#${
                anchorAndQuery[0]
              }`
            )
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const { publicRuntimeConfig } = getConfig()
  const router = useRouter()
  const asPath = router.asPath.split('?')[0]
  const canonicalUrlDefault = `${hostProtocol}${host}${asPath === '/' ? '' : asPath}${
    router?.query?.page > 1 ? '?page=' + router.query.page : ''
  }`

  // Remove unneeded page props from being passed to child components
  const {
    emergencyBanners,
    ffamReferrerContent,
    footer,
    header,
    isFFAMReferrer,
    featurePathOverrides = {},
    phoneData,
    tcbBannerLayout = false,
    // Only pass the remaining props to child components
    ...restPageProps
  } = pageProps

  const pageTitle = `${pageProps?.content?.fields?.pageMetaTitle}${
    router?.query?.page > 1 ? ' page ' + router.query.page : ''
  }`

  //Initialize the quiz name and page
  let quiz_name = ''
  let quiz_page = ''

  // Initialize phoneDataFiltered as an empty object to be passed into PhoneDataProvider and set to the correct values if the page meets the following conditions:
  let phoneDataFiltered = {}

  // Get the phone feature toggle value from the featureToggleObject
  const phoneFeatureToggle =
    pageProps?.featureToggleObject?.[FEATURE_TOGGLES.ACX_WEB_ENABLE_PHONE_NUMBERS]

  // Check if the current path is not the root path
  const isNotRootPath = pageProps?.content?.fields?.path !== '/'

  // Check if the phone feature toggle is on (i.e., it's not unassigned, default, or off)
  // For performance gains regarding cache we need to move all the section based experiments to the client
  // So on every single request We will be returning the control version no matter what and only on the client
  // according to the feature flag value are we going to hide or show the correct section
  // we need also to remove from the DOM the variation sections until it reach the client
  // the phone experiment is also a section level experiment
  const isPhoneFeatureToggleOn =
    phoneFeatureToggle != UNASSIGNED_EXPERIMENT &&
    phoneFeatureToggle != DEFAULT_EXPERIMENT &&
    phoneFeatureToggle != OFF_EXPERIMENT

  // Check if phone data is available (i.e., both phoneNumbers and phoneCopy exist)
  const hasPhoneData = phoneData?.phoneNumbers && phoneData?.phoneCopy

  const phoneNumberDisabledForPath =
    Object.hasOwn(featurePathOverrides, FEATURE_TOGGLES.ACX_WEB_ENABLE_PHONE_NUMBERS) &&
    !featurePathOverrides[FEATURE_TOGGLES.ACX_WEB_ENABLE_PHONE_NUMBERS]

  // Check if the current path is not the root path, the phone feature toggle is on, phone data is
  // available, and the phone experiment is not disabled for this page
  if (isNotRootPath && isPhoneFeatureToggleOn && hasPhoneData && !phoneNumberDisabledForPath) {
    // Get the associated product from the page props, defaulting to 'None' if it's not defined
    const associatedProduct = pageProps?.content?.fields?.associatedProduct ?? 'None'
    // Filter the phone data based on the associated product
    phoneDataFiltered = {
      phoneNumber: phoneData.phoneNumbers[associatedProduct],
      phoneCopy: phoneData.phoneCopy[associatedProduct],
    }
  }

  if (pageProps?.content?.fields?.phoneOverride) {
    phoneDataFiltered = {
      phoneNumber: pageProps?.content?.fields?.phoneOverride,
    }
  }

  // Here We need to get the value of the current experimentation on the page level
  // If the value is not either off or Unassigned We print the correct experiment variation
  // Also on pageVariation We set the variation to handle all the events

  const experimentToggle = router?.query?.v
    ? getPageVariationByParam(router?.query?.v)
    : pageProps?.featureToggleObject?.[pageProps?.content?.fields?.experiment?.featureFlagId]
  let pageVariation =
    experimentToggle === pageProps?.content?.fields?.experimentVariation ||
    (experimentToggle === UNASSIGNED_EXPERIMENT &&
      pageProps?.content?.fields?.experimentVariation === DEFAULT_EXPERIMENT)
      ? {
          id: pageProps?.content?.fields?.experiment?.featureFlagId,
          name: pageProps?.content?.fields?.experiment?.name,
          variation: experimentToggle,
        }
      : null
  let pageSectionsVariations = []
  let pageSectionVariation = false
  pageProps?.content?.fields?.sections?.map((section) => {
    // Get the name of the quiz and the page
    if (section?.fields?.uiComponent === 'DebtQuiz') {
      quiz_name = section?.fields?.name
      quiz_page = `${section?.fields?.name} - Landing Page`
    }
    // Here We need to get the value of the current experimentation on the section level
    // If the value is not either off or Unassigned We print the correct experiment variation
    // Also on pageSectionsVariations We set the variation list to handle all the events for this page section only
    if (section?.fields?.experiment?.featureFlagId) {
      const experimentToggleSection =
        pageProps?.featureToggleObject?.[section?.fields?.experiment?.featureFlagId]
      pageSectionVariation = Boolean(
        section?.fields?.experimentVariation === experimentToggleSection ||
          (section?.fields?.experimentVariation === DEFAULT_EXPERIMENT &&
            experimentToggleSection === UNASSIGNED_EXPERIMENT)
      )
      if (section?.fields?.experiment?.pageLevelExperiment && pageSectionVariation) {
        //force section level experiment to be page level for all sections
        pageVariation = {
          id: section?.fields?.experiment?.featureFlagId,
          name: section?.fields?.experiment?.name,
          variation: experimentToggleSection,
        }
      } else {
        if (pageSectionVariation) {
          pageSectionsVariations.push({
            id: section?.fields?.experiment?.featureFlagId,
            name: section?.fields?.experiment?.name,
            variation: experimentToggleSection,
          })
        }
      }
    }
  })

  return (
    <FeatureFlagProvider
      featurePathOverrides={pageProps.featurePathOverrides || {}}
      featureToggleObject={pageProps.featureToggleObject || {}}
    >
      <AnalyticsProvider
        config={publicRuntimeConfig}
        pageVariation={pageVariation}
        pageSectionsVariations={pageSectionsVariations}
        loadEventProps={{
          ...(router.pathname === '/learn/[categoryname]/[slug]' && {
            content_type: pageProps?.content?.items?.[0]?.fields.contentType,
          }),
          ...(quiz_name && {
            quiz_name: quiz_name,
            quiz_page: quiz_page,
          }),
        }}
      >
        <CacheProvider value={emotionCache}>
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            <AscendThemeProvider>
              <PhoneDataProvider phoneData={phoneDataFiltered}>
                <Layout
                  publicProcessDotEnv={publicProcessDotEnv}
                  metaDescription={pageProps?.content?.fields?.metaDescription}
                  pageMetaData={pageProps?.content?.fields?.pageMetaData}
                  pageMetaTitle={pageTitle}
                  pageTitle={pageProps?.content?.fields?.pageTitle}
                  canonicalLink={
                    pageProps?.content?.fields?.canonicalLinkOverride || canonicalUrlDefault
                  }
                  pageDisclosure={pageProps?.content?.fields?.pageDisclosure}
                  secondaryDisclosures={pageProps?.content?.fields?.secondaryDisclosures}
                  featureToggleObject={pageProps.featureToggleObject}
                  isFFAMReferrer={isFFAMReferrer}
                  isMobileUA={pageProps.isMobile}
                  ffamReferrerContent={ffamReferrerContent}
                  header={header}
                  footer={footer}
                  emergencyBanners={emergencyBanners || []}
                  pageConfig={pageProps?.content?.fields?.pageConfig}
                  showHeader={!tcbBannerLayout}
                  showFooter={!tcbBannerLayout}
                  alignContentBottom={tcbBannerLayout}
                >
                  <DTIProvider>
                    <Component {...restPageProps} />
                  </DTIProvider>
                </Layout>
              </PhoneDataProvider>
            </AscendThemeProvider>
          </StyledEngineProvider>
        </CacheProvider>
      </AnalyticsProvider>
    </FeatureFlagProvider>
  )
}

const getObjectOfPublicEnvs = () => {
  // Get properties from process.env that have keys that start with NEXT_PUBLIC_
  const publicEnvs = Object.entries(process.env).filter(([key]) => key.startsWith('NEXT_PUBLIC_'))
  // Return an object with the keys and values of the public envs
  return publicEnvs.reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {})
}

// This is required to inject environment variables client side after build time.
// NOTE that this also disables automatic static optimization
MyApp.getInitialProps = async (props) => {
  // Get tealium URL from env var. Typically this would be inlined at build time but we do a single build.
  const publicProcessDotEnv = getObjectOfPublicEnvs()
  const referer = props?.ctx?.req?.headers?.referer
  const initProps = await App.getInitialProps(props)
  const host = props?.ctx?.req?.headers?.host
  const protocol =
    props?.ctx?.req?.headers['x-forwarded-proto'] || props?.ctx?.req?.connection.encrypted
      ? 'https://'
      : 'http://'
  // Return our initial props and our new props
  return { ...initProps, publicProcessDotEnv, referer, host, hostProtocol: protocol }
}

export default MyApp
